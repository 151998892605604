<template>
    <div class="form-wrap">
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px">
            <el-row :gutter="20">
                <el-col :span="24">
                    <el-form-item
                        label-width="500px"
                        label="您在前一年，全年收入是否达港币100万元或以上"
                        prop="income_over_100"
                        :rules="{ required: true, message: '请选择', trigger: 'change' }"
                    >
                        <template #label>
                            <span>您在前一年，全年收入是否达港币100万元或以上</span>
                            <div class="remark">备注：全年收入是指应课税（应纳所得税）的就业或业务收入，包括工资薪金、津贴、股票期权及从其拥有的公司所得的利润。由个人投资所产生的收入不会计算在内；（申报公司需与工作经验申报的公司保持一致）</div>
                        </template>
                        <el-radio-group v-model="ruleForm.income_over_100">
                            <el-radio :label="1" :disabled="!(ruleForm.items && ruleForm.items.length)">是</el-radio>
                            <el-radio :label="2" >否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <template v-if="+ruleForm.income_over_100 === 1 && ruleForm.items && ruleForm.items.length">
                <el-row :gutter="20"
                    v-for="(item, index ) of ruleForm.items"
                    :key="index">
                    <div class="sub-info-title">收入记录{{ index + 1 }}</div>
                    <el-col :span="12">
                        <el-form-item label="收入来源公司" >
                            <span>{{ item.company_name }}</span>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="收入类型"
                            :prop="'items.' + index + '.income_type'"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }">
                            <el-select
                                v-model="item.income_type"
                                clearable
                                multiple
                                collapse-tags>
                                <el-option
                                    v-for="(item, key) in INCOME_TYPE"
                                    :key="key"
                                    :label="item.name"
                                    :value="+key">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12" v-if="showCorporateTurnover(item, index)">
                        <el-form-item
                            label="公司营业额/人民币 (近一年)"
                            label-width="205px"
                            :prop="'items.' + index + '.corporate_turnover'"
                            >
                            <el-input
                                v-model="item.corporate_turnover"
                                clearable
                                v-restrict-number
                                placeholder="请输入" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item
                            label="收入金额"
                            :rules="{ required: true, message: '请输入', trigger: 'change' }"
                            :prop="'items.' + index + '.income_money'">
                            <el-input
                                v-model="item.income_money"
                                clearable
                                v-restrict-number
                                placeholder="请输入" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="你是否可以提供以下所有证明"
                            :rules="{ required: true, message: '请选择', trigger: 'change' }"
                            :prop="'items.' + index + '.has_all_file'"
                            label-width="205px">
                            <el-radio-group
                                v-model="item.has_all_file"
                               >
                                <el-radio :label="1">是</el-radio>
                                <el-radio :label="2">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-if="item.income_type && item.income_type.length">
                        <el-form-item label="" label-width="0" >
                            <div class="table-info">
                                <div
                                    v-for="(item, index) in getCertificateList(item)"
                                    :key="index"
                                    class="table-item">
                                    {{ item }}
                                </div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="总收入" prop="totalMoney" class="total">
                            <span>{{ totalMoney }}</span>
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>
        </el-form>
    </div>
</template>

<script>
import { INCOME_TYPE } from '../config'
import { add, formatThousandsSeparator } from '@/utils/utils'

export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            INCOME_TYPE,
            ruleForm: {
                income_over_100: '',
                items: [
                    {
                        company_name: '公司名称',
                        has_all_file: '', // 是否可以提供所有证明
                        income_money: '', // 收入金额
                        income_type: [], // 收入类型
                        corporate_turnover: '', // 公司营业额/人民币 (近一年)
                    },
                    {
                        company_name: '公司名称',
                        has_all_file: '', // 是否可以提供所有证明
                        income_money: '', // 收入金额
                        income_type: [], // 收入类型
                        corporate_turnover: '', // 公司营业额/人民币 (近一年)
                    },
                ],
            },
        }
    },
    created() {
        this.ruleForm = this.data
        this.clearValidate()
    },
    methods: {
        showCorporateTurnover(row, index) {
            const flag = (row.income_type || []).some(item => this.showCorporateTurnoverList.includes(+item));
            if(!flag) {
                this.ruleForm.items[index].corporate_turnover = ''
            }
            this.clearValidate('items.' + index + '.corporate_turnover')
            return flag;
        },
        getCertificateList(row) {
            const set = new Set();
            (row.income_type || []).forEach(item => {
                const result = this.allCertificateReflect[+item]
                if(result && result.length) {
                    result.forEach(Child => {
                        set.add(Child)
                    })
                }
            });
            return [...set]
        },
        clearValidate(key = '') {
            setTimeout(() => {
                this.$refs.ruleForm && this.$refs.ruleForm.clearValidate(key);
            }, 50)
        },
        save () {
            let that = this;
            return new Promise(function (resolve, reject) {
                that.$refs.ruleForm.validate( valid => {
                    if (valid) {
                        resolve(valid)
                    } else {
                        reject();
                    }
                })
            })
        }
    },
    computed: {
       showCorporateTurnoverList() {
            return  Object.keys(INCOME_TYPE).reduce((pre, key) => {
                if (INCOME_TYPE[key].showCorporateTurnover) {
                    pre.push(+key)
                }
                return pre;
            }, [])
       },
       allCertificateReflect() {
            return  Object.keys(INCOME_TYPE).reduce((pre, key) => {
                pre[key] = INCOME_TYPE[key].certificate
                return pre;
            }, {})
       },
       totalMoney() {
            const total = (this.data.items || []).reduce((pre, item) => {
                if(item.income_money && !isNaN(+item.income_money)) {
                    pre = add(pre, item.income_money);
                }
                return pre;
            }, 0);
            return formatThousandsSeparator(total);
       },
    },
}
</script>

<style lang="less" scoped>
.form-wrap {
    padding: 12px;
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
        display: flex;
        .el-input, .el-select {
            width: 100% ;
        }
        .el-radio-group {
            line-height: 32px;
            position: relative;
            transform: translateY(4px);
            .el-radio + .el-radio {
                margin-left: 20px;
            }
        }
        .table-info {
            margin-top: -4px;
            display: flex;
            flex-direction: column;
            width: 100%;
            .table-item {
                border: 1px solid #DCDFE6;
                padding-left: 12px;
                color: #666;
                & + .table-item {
                    border-top: none;
                }
            }
        }
    }
    .sub-info-title {
        margin: 0 20px 16px;
        font-weight: 700;
    }
    .remark {
        color: #909399;
        font-size: 12px;
        line-height: 16px;
    }
    .total {
        /deep/.el-form-item__label {
            font-weight: 700;
            color: #000;
        }
    }
}
</style>
