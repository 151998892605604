<!--
 * @Author: filename
 * @Description: 家庭成员-配偶信息
-->

<template>
    <div class="details-module common-part">
        <el-row>
            <el-col :span="12" id="spouse_name">
                <span>姓名：</span>
                <span>{{ data.subset_name }}</span>
            </el-col>
            <el-col :span="12" id="spouse_used_name">
                <span>曾用名：</span>
                <span>{{ data.used_name }}</span>
            </el-col>
            <el-col :span="12" id="spouse_used_name">
                <span>婚前姓氏：</span>
                <span>{{ data.surname || "-" }}</span>
            </el-col>
            <el-col :span="12" id="spouse_name-pinyin">
                <span>姓名拼音：</span>
                <span>{{ data.subset_name_pinyin.family_name + ' ' + data.subset_name_pinyin.given_name }}</span>
            </el-col>
            <el-col :span="12" id="spouse_sex">
                <span>性别：</span>
                <span>{{ data.sex | _sex }}</span>
            </el-col>
            <el-col :span="12" id="spouse_birthday">
                <span>出生日期：</span>
                <span>{{ data.birthday }}
                <span v-if="data.birthday" style="color: red;">({{ ages(data.birthday) + '岁' }})</span></span>
            </el-col>
            <el-col :span="12" id="spouse_address">
                <span>出生城市：</span>
                <span v-if="data.birth_place_aboard === 0">{{ data.birth_place.details }}</span>
                <span v-if="data.birth_place_aboard === 1">{{ data.birth_place.foreign }}</span>
            </el-col>
            <!-- <el-col :span="12" id="spouse_occupation">
                <span>是否在职：</span>
                <span>{{ data.occupation }}</span>
            </el-col>
            <el-col :span="12" id="spouse_occupation_name">
                <span>职位名称：</span>
                <span>{{ data.occupation_name }}</span>
            </el-col> -->
            <template v-if="data.follow == 1">
                <el-col :span="12" id="spouse_nationality">
                    <span>国籍/地区：</span>
                    <span>{{ data.nationality }}</span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <!-- <el-col :span="12">
                    <span>是否有学位：</span>
                    <span class="red">{{ data.has_degree == 1 ? '有学位' : data.has_degree == 0 ? '无学位' : '' }}</span>
                </el-col>
                <el-col :span="12" style="align-items: normal;" v-if="data.has_degree == 1">
                    <span>学位证：</span>
                    <span>
                        <span style="display: block;" v-for="(item, i) in data.has_degree_json" :key="i">
                            {{ item.degree == 1 ? '学士学位' : item.degree == 2 ? '硕士学位' : item.degree == 3 ? '博士学位' : '' }}
                            -
                            {{ item.address == 1 ? '国内（颁发）' : item.address == 2 ? '国外（颁发）' : '' }}
                        </span>
                    </span>
                </el-col> -->
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="12" id="spouse_isCohabitation">
                    <span>是否与主申请人同住：</span>
                    <span class="red">{{ data.nows == 1 ? '是' : data.nows == 0 ? '否' : '' }}</span>
                </el-col>
                <el-col :span="12">
                    <span>是否永久居留身份：</span>
                    <span>{{ data.permanent_residence == 1 ? '是' : data.permanent_residence == 0 ? '否' : '' }}</span>
                </el-col>
                <div>
                    <el-col :span="inChina ? 24 : 12" style="align-items: normal;" v-if="data.nows == 0" id="spouse_country">
                        <span>现居住地址：</span>
                        <span id="spouse_country_detail" v-if="data.address.country && inChina">{{ data.address.country + ' - ' + data.address.area[0] + data.address.area[1] + ' - ' + data.address.details }}</span>
                        <span id="spouse_country_detail" v-else >{{ data.address.country + ' - ' + data.address.foreign }}</span>
                    </el-col>
                    <el-col :span="12" id="user_address" v-if="!inChina">
                        <span>是否居住满一年及以上：</span>
                        <span>{{ data.is_live_oversea_year === 1 ? '是' : '否' }}</span>
                    </el-col>
                </div>
                <!-- <el-col :span="12" id="spouse_permanent_reside_year">
                    <span>总居留时间：</span>
                    <span>{{ data.permanent_reside_year }}年{{ data.permanent_reside_month }}月</span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24">
                    <span>是否在港：</span>
                    <span class="red" v-if="data.child_in_hk.has">{{ data.child_in_hk.has == 'Y' ? '是' : '否' }}</span>
                </el-col>
                <template v-if="data.child_in_hk.has == 'Y'">
                    <el-col :span="12">
                        <span>获准逗留至：</span>
                        <span>{{ data.child_in_hk.info }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>在港身份：</span>
                        <span>{{ data.hk_identity == '其他' ? data.hk_identity_other : data.hk_identity }}</span>
                    </el-col>
                </template> -->
            </template>
            <el-col :span="24">
                <el-divider />
            </el-col>
            <el-col :span="12">
                <span>是否办理过香港身份：</span>
                <span class="red" v-if="data.child_has_hk_id.has">{{ data.child_has_hk_id.has == 'Y' ? '是' : '否' }}</span>
            </el-col>
            <el-col :span="12" v-if="data.child_has_hk_id.has == 'Y'" id="spouse_hk_identity_card">
                <span>香港身份证：</span>
                <span>{{ data.hk_identity_card }}</span>
            </el-col>
            <template v-if="data.follow == 1">
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <!-- <el-col :span="24">
                    <span>证件类别：</span>
                    <span>中国身份证</span>
                </el-col>
                <el-col :span="12" id="spouse_cn_identity_card">
                    <span>证件号码：</span>
                    <span>{{ data.certificates.cn_identity_card.number }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发地：</span>
                    <span>{{ data.certificates.cn_identity_card.issue_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发日期：</span>
                    <span>{{ data.certificates.cn_identity_card.issue_date_start_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>届满日期：</span>
                    <span>{{ data.certificates.cn_identity_card.issue_date_end_at }}</span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col> -->
                <el-col :span="24" id="spouse_certificates_name">
                    <span>证件类别：</span>
                    <span>港澳通行证</span>
                </el-col>
                <el-col :span="12" id="spouse_certificates_number">
                    <span>证件号码：</span>
                    <span>{{ data.certificates.hk_macao_pass.number }}</span>
                </el-col>
                <el-col :span="12" id="spouse_certificates_issue_at">
                    <span>签发地：</span>
                    <span>{{ data.certificates.hk_macao_pass.issue_at }}</span>
                </el-col>
                <el-col :span="12" id="spouse_certificates_issue_date_start_at">
                    <span>签发日期：</span>
                    <span>{{ data.certificates.hk_macao_pass.issue_date_start_at }}</span>
                </el-col>
                <el-col :span="12" id="spouse_certificates_issue_date_end_at">
                    <span>届满日期：</span>
                    <span>{{ data.certificates.hk_macao_pass.issue_date_end_at }}</span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24" id="spouse_certificates_name2">
                    <span>证件类别：</span>
                    <span>{{ data.certificates.passport.passport_type | _passportType }}</span>
                </el-col>
                <el-col :span="12" id="spouse_certificates_number2">
                    <span>证件号码：</span>
                    <span>{{ data.certificates.passport.number }}</span>
                </el-col>
                <el-col :span="12" id="spouse_certificates_issue_at2">
                    <span>签发地：</span>
                    <span>{{ data.certificates.passport.issue_at }}</span>
                </el-col>
                <el-col :span="12" id="spouse_certificates_issue_date_start_at2">
                    <span>签发日期：</span>
                    <span>{{ data.certificates.passport.issue_date_start_at }}</span>
                </el-col>
                <el-col :span="12" id="spouse_certificates_issue_date_end_at2">
                    <span>届满日期：</span>
                    <span>{{ data.certificates.passport.issue_date_end_at }}</span>
                </el-col>
            </template>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
        }
    },
    methods: {
        ages(str) {
            var r = str.match(/^(\d{1,4})(-|\/)(\d{1,2})\2(\d{1,2})$/);
            if (r == null) return false
            var d = new Date(r[1], r[3] - 1, r[4]);
            if (d.getFullYear() == r[1] && (d.getMonth() + 1) == r[3] && d.getDate() == r[4]) {
                var Y = new Date().getFullYear();
                return Y - r[1]
            }
        }
    },
    computed: {
        inChina() {
            return this.data.address.country.includes('中国')
        }
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
#spouse_country {
    align-items: flex-start;

    &_detail {
        padding-right: 15px;
    }
}
</style>
