<template>
    <div class="common-part details-module">
        <el-row>
            <el-col :span="24" id="income_over_100">
                <span>您在前一年，全年收入是否达港币100万元或以上：</span>
                <span>{{ YES_OR_NO[data.income_over_100] || '未知' }}</span>
            </el-col>
        </el-row>
        <template v-if="+data.income_over_100 === 1 && data.items && data.items.length">
            <el-row
                v-for="(item, index ) of data.items"
                :key="index">
                <div class="sub-info-title">收入记录{{ index + 1 }}</div>
                <el-col :span="12">
                    <span>收入来源公司：</span>
                    <span>{{ item.company_name }}</span>
                </el-col>
                <el-col :span="12">
                    <span>收入类型：</span>
                    <span>{{ getIncomeTypeText(item.income_type || []) || '-' }}</span>
                </el-col>
                <el-col :span="12" v-if="showCorporateTurnover(item)">
                    <span>公司营业额/人民币 (近一年)：</span>
                    <span>{{ formatThousandsSeparator(item.corporate_turnover) }}</span>
                </el-col>
                <el-col :span="12">
                    <span>收入金额：</span>
                    <span>{{ formatThousandsSeparator(item.income_money) }}</span>
                </el-col>
                <el-col :span="24">
                    <span>你是否可以提供以下所有证明：</span>
                    <span>{{ YES_OR_NO[item.has_all_file] || '未知' }}</span>
                </el-col>
                <el-col :span="24" v-if="item.income_type && item.income_type.length">
                    <div class="table-info">
                        <div
                            v-for="(item, index) in getCertificateList(item)"
                            :key="index"
                            class="table-item">
                            {{ item }}
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12" id="totalMoney">
                    <span class="total">总收入：</span>
                    <span>{{ totalMoney }}</span>
                </el-col>
            </el-row>
        </template>
    </div>
</template>
<script>
import { INCOME_TYPE } from '../config'
import { add, formatThousandsSeparator } from '@/utils/utils'
export default {
    props: {
        data: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            YES_OR_NO: {
                1: '是',
                2: '否',
            },
            INCOME_TYPE,
            formatThousandsSeparator,
        };
    },
    methods: {
        getIncomeTypeText(typeList) {
            return (typeList || []).map((item) =>INCOME_TYPE[+item].name).join(',')
        },
        showCorporateTurnover(row) {
            const flag = (row.income_type || []).some(item => this.showCorporateTurnoverList.includes(+item));
            return flag;
        },
        getCertificateList(row) {
            const set = new Set();
            (row.income_type || []).forEach(item => {
                const result = this.allCertificateReflect[+item]
                if(result && result.length) {
                    result.forEach(Child => {
                        set.add(Child)
                    })
                }
            });
            return [...set]
        },
    },
    computed: {
       showCorporateTurnoverList() {
            return  Object.keys(INCOME_TYPE).reduce((pre, key) => {
                if (INCOME_TYPE[key].showCorporateTurnover) {
                    pre.push(+key)
                }
                return pre;
            }, [])
       },
       allCertificateReflect() {
            return  Object.keys(INCOME_TYPE).reduce((pre, key) => {
                pre[key] = INCOME_TYPE[key].certificate
                return pre;
            }, {})
       },
       totalMoney() {
            const total = (this.data.items || []).reduce((pre, item) => {
                if(item.income_money && !isNaN(+item.income_money)) {
                    pre = add(pre, item.income_money);
                }
                return pre;
            }, 0);
            return formatThousandsSeparator(total);
       },
    },
};
</script>

<style lang="less" scoped>
.common-part {
    .sub-info-title {
        margin: 16px 0 10px;
        font-weight: 700;
    }
    .table-info {
        margin-top: -4px;
        display: flex;
        flex-direction: column;
        font-size: 14px;
        width: 100%;
        .table-item {
            border: 1px solid #DCDFE6;
            padding-left: 12px;
            color: #666;
            & + .table-item {
                border-top: none;
            }
        }
    }
    .el-row .el-col {
        align-items: flex-start !important;
    }
    .total {
        font-weight: 700;
        color: #000 !important;
    }
}
</style>
