export const INCOME_TYPE = {
    1 :  {
        name : '内地公司收入 (持股 50% 及以上)',
        certificate : [
            '验资报告',
            '审计报告',
            '绝对实益拥有人声明书',
            '企业所得税月（季）度预缴纳税申报表（A类）（前4个季度/前12个月）',
            '企业所得税完税证明（表格式）',
            '企业所得税年度纳税申报表（A类）（近一年度）',
            '工作证明',
        ],
        showCorporateTurnover: true,
    },
    2 : {
        name : '香港公司收入 (持股 50% 及以上)',
        certificate : [
            '周年申报表',
            '审计报告',
            '利得税申请表',
            '利得税缴纳通知书',
            '缴税凭证',
            '绝对实益拥有人声明书',
            '工作签证',
            '工作证明',
        ],
        showCorporateTurnover: true,
    },
    3 : {
        name: '澳门公司收入 (持股 50% 及以上)',
        certificate: [
            '商业登记证明',
            '审计报告',
            '所得补充税 B 组 —— 收益申报书（M1）',
            '所得补充税 —— 收益评定通知书（M5）',
            '缴税凭证',
            '绝对实益拥有人声明书',
            '工作签证',
            '工作证明',
        ],
        showCorporateTurnover: true,
    },
    4 : {
        name: '上市公司股票期权或股权收入',
        certificate : [
            '纳税记录',
            '纳税清单',
            '股票或期权的发放证明（能看到个人的发放股数和每股价值）',
            '工作证明',
        ],
    } ,
    5 : {
        name: '工资薪金所得及全年一次性奖金',
        certificate : [
            '纳税记录',
            '纳税清单',
            '工作证明',
        ],
    },
    6 :{
        name:  '持股公司分红收入',
        certificate:[
            '纳税记录',
            '纳税清单',
            '持股证明',
            '股东分红决议',
            '分红入账记录（银行回单）',
            '工作证明',
        ],
    },
    7 : {
        name: '股权转让收入',
        certificate : [
            '纳税记录',
            '纳税清单',
            '股权转让相关入账记录（银行回单）',
            '股权转让协议',
            '股权交割证明或股权变更登记表（显示公司股权占比更改）',
            '完税证明（表格式）',
            '工作证明',
        ],
    },
    8 : {
        name: '个体工商户/合伙企业/独资企业经营所得收入',
        certificate: [
            '纳税记录',
            '纳税清单',
            '个人所得税经营所得纳税申报表（A 表）（前 4 个季度 / 前 12 个月）',
            '个人所得税经营所得纳税申报表（A 表 & B 表）（最新年度）',
            '个人所得税经营所得纳税申报表（C 表）',
            '工作证明',
        ],
    },
    9 : {
        name: '其他境外公司收入 (持股 50% 及以上)',
        certificate : [
            '财务报表',
            '合法纳税及完税证明',
            '公司所有权官方证明文件',
            '工作签证',
            '工作证明',
        ],
        showCorporateTurnover: true,
    }
}


