export const FILE_MAP = {
    "内地":[
        "验资报告",
        "审计报告",
        "绝对实益拥有人声明书",
        "企业所得税月（季）度预缴纳税申报表（A类）（前4个季度/前12个月）",
        "企业所得税完税证明（表格式）",
        "企业所得税年度纳税申报表（A类）（近一年度）",
        "工作证明"
    ],
    "香港":[
        "周年申报表",
        "审计报告",
        "利得税申请表",
        "利得税缴纳通知书",
        "缴税凭证",
        "绝对实益拥有人声明书",
        "工作签证",
        "工作证明"
    ],
    "澳门":[
        '商业登记证明',
        '审计报告',
        '所得补充税B组──收益申报书（M1）',
        '所得补充税—收益评定通知书（M5）',
        '缴税凭证',
        '绝对实益拥有人声明书',
        '工作签证',
        '工作证明'
    ],
    "境外":[
        '财务报表',
        '合法纳税及完税证明',
        '公司所有权官方证明文件',
        '工作签证',
        '工作证明'
    ]
}
