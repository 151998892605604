<!--
 * @Description: 收入信息
-->
<template>
    <div>
        <Container title="收入信息（仅申报近12个月应纳税收入总额）" padding="0">
            <!-- 回显信息 -->
            <Detail v-if="!isEdit" :data="info"/>
            <!-- 编辑 -->
            <Edit ref="editForm" v-if="isEdit" v-loading="isLoading" :data="income"/>
            <div slot="handle">
                <div class="handle-btn mr-15">
                    <el-button v-if="!isEdit" type="primary" @click="editInfo()">编辑</el-button>
                    <el-button v-if="isEdit" @click="cancel">取消</el-button>
                    <el-button v-if="isEdit" :loading="isLoading" type="primary" @click="saveSuccess">保存</el-button>
                </div>
            </div>
        </Container>
    </div>
</template>

<script>
import Detail from './C/Detail.vue'
import Edit from './C/Edit.vue'
import { postClientInfo } from '@/api/gifted'
import { mapState } from 'vuex'

export default {
    props: {
        info: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        Detail,
        Edit
    },
    data() {
        return {
            isEdit: false,
            isLoading: false,
        }
    },
    created() {
        console.log(this.info, "收入信息-")
        this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'income', data: JSON.parse(JSON.stringify(this.info)) })
    },
    computed: {
        ...mapState('previewPdfGifted', ['income'])
    },
    methods: {
        editInfo() {
            this.isEdit = true;
        },
        // 保存成功
        saveSuccess() {
            const edit = this.$refs['editForm'].save();
            Promise.all([edit]).then(() => {
                console.log(edit, "收入信息编辑")
                //全部验证通过就会走这里
                this.postData()
            }, err => {
                this.$utils.scrollIntoError(this);
            })
        },
        async postData() {
            this.isLoading = true;
            try {
                console.log(this.income, "收入信息")
                const { code } = await postClientInfo(this.income)
                if (code === 200) {
                    this.$message.success("保存成功！");
                    await this.$utils.saveGiftedStatus(this.$route.params.id);
                    this.isEdit = false;
                }
            } finally {
                this.isLoading = false
            }
        },
        cancel() {
            this.$store.dispatch('previewPdfGifted/setStateKeyData', { key: 'income', data: JSON.parse(JSON.stringify(this.info)) })
            this.isEdit = false;
        }
    },
}
</script>
